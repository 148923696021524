#header {
  &.navbar-fixed-homepage {
    top: 0;
    border-width: 0 0 1px;
    position: absolute;
    width: 100%;
    background: none;
  }
}

#homepage {
  #homepage-title {
    color: @grey-dark;
    font-family: @font-family-light;
  }
}