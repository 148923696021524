// Credits to:: https://gist.github.com/wdollar/135ec3c80faaf5a821b0

// Bootstrap Mid-Small - col-ss-* - the missing grid set for Bootstrap3.
//
// This is a hack to fill the gap between 480 and 767 pixels - a missing range
// in the bootstrap responsive grid structure. Use these classes to style pages
// on cellphones when they transition from portrait to landscape.
//
// Contains:
// Columns, Offsets, Pushes, Pulls for the Mid-Small layout
// Visibility classes for the Mid-Small layout
// Redefined visibility classes for the Extra Small layout
//
// See https://github.com/twbs/bootstrap/issues/10203 for more info.
// Forked from: https://gist.github.com/Jakobud/8eca95f07a3b50453cd7
// Original gist: https://gist.github.com/andyl/6360906
//
// Instructions: Add the following to the end of bootstrap.less
//
// @import "bootstrap-ss";
//

// Mid-Small breakpoint

@screen-ss: 480px;
@screen-ss-min: @screen-ss;
@screen-ss-max: (@screen-sm-min - 1);

// Redefined Extra Small max value
@screen-xs-max-new: (@screen-ss-min - 1);

// Misc. class adjustments for col-ss
@media (min-width: @screen-ss) {
  .make-grid(ss);
}

@media (min-width: @screen-ss) and (max-width: @screen-ss-max) {
  .container {
    max-width: @screen-sm - 20px;
  }

  .hidden-xs {
    display: block !important;
  }
}


// Visibility utilities
// Note: Deprecated .visible-xs, .visible-sm, .visible-md, and .visible-lg as of v3.2.0
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  .responsive-invisibility();
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-ss-block,
.visible-ss-inline,
.visible-ss-inline-block
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

.visible-xs {
  @media (max-width: @screen-xs-max) {
    .responsive-visibility();
  }
}

.visible-xs-block {
  @media (max-width: @screen-xs-max) {
    display: block !important;
  }
}

.visible-xs-inline {
  @media (max-width: @screen-xs-max) {
    display: inline !important;
  }
}

.visible-xs-inline-block {
  @media (max-width: @screen-xs-max) {
    display: inline-block !important;
  }
}

@media (min-width: @screen-ss-min) and (max-width: @screen-ss-max) {
  .visible-ss {
    .responsive-visibility;
  }
}

.visible-ss-block {
  @media (min-width: @screen-ss-min) and (max-width: @screen-ss-max) {
    display: block !important;
  }
}

.visible-ss-inline {
  @media (min-width: @screen-ss-min) and (max-width: @screen-ss-max) {
    display: inline !important;
  }
}

.visible-ss-inline-block {
  @media (min-width: @screen-ss-min) and (max-width: @screen-ss-max) {
    display: inline-block !important;
  }
}

.visible-sm {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .responsive-visibility();
  }
}

.visible-sm-block {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    display: block !important;
  }
}

.visible-sm-inline {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    display: inline !important;
  }
}

.visible-sm-inline-block {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    display: inline-block !important;
  }
}

.visible-md {
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .responsive-visibility();
  }
}

.visible-md-block {
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    display: block !important;
  }
}

.visible-md-inline {
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    display: inline !important;
  }
}

.visible-md-inline-block {
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    display: inline-block !important;
  }
}

.visible-lg {
  @media (min-width: @screen-lg-min) {
    .responsive-visibility();
  }
}

.visible-lg-block {
  @media (min-width: @screen-lg-min) {
    display: block !important;
  }
}

.visible-lg-inline {
  @media (min-width: @screen-lg-min) {
    display: inline !important;
  }
}

.visible-lg-inline-block {
  @media (min-width: @screen-lg-min) {
    display: inline-block !important;
  }
}

.hidden-xs {
  @media (max-width: @screen-xs-max) {
    .responsive-invisibility();
  }
}

.hidden-ss {
  @media (min-width: @screen-ss-min) and (max-width: @screen-ss-max) {
    .responsive-invisibility;
  }
}

.hidden-sm {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .responsive-invisibility();
  }
}

.hidden-md {
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .responsive-invisibility();
  }
}

.hidden-lg {
  @media (min-width: @screen-lg-min) {
    .responsive-invisibility();
  }
}
