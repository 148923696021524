@font-face {
  font-family: '@{font-name-open-sans-regular}';
  src: url('@{font-path-open-sans}@{font-name-open-sans-regular}.ttf') format('truetype');
}

@font-face {
  font-family: '@{font-name-open-sans-light}';
  src: url('@{font-path-open-sans}@{font-name-open-sans-light}.ttf') format('truetype');
}

@font-face {
  font-family: '@{font-name-open-sans-semi-bold}';
  src: url('@{font-path-open-sans}@{font-name-open-sans-semi-bold}.ttf') format('truetype');
}

@font-face {
  font-family: '@{font-name-open-sans-bold}';
  src: url('@{font-path-open-sans}@{font-name-open-sans-bold}.ttf') format('truetype');
}

@font-face {
  font-family: '@{font-name-open-sans-cond-light}';
  src: url('@{font-path-open-sans-cond}@{font-name-open-sans-cond-light}.ttf') format('truetype');
}

@font-face {
  font-family: '@{font-name-open-sans-cond-bold}';
  src: url('@{font-path-open-sans-cond}@{font-name-open-sans-cond-bold}.ttf') format('truetype');
}

@font-face {
  font-family: '@{application-font-name}';
  src: url('@{application-font-path}@{application-font-name}.eot');
  src: url('@{application-font-path}@{application-font-name}.eot?#iefix') format('embedded-opentype'),
  url('@{application-font-path}@{application-font-name}.woff') format('woff'),
  url('@{application-font-path}@{application-font-name}.ttf') format('truetype'),
  url('@{application-font-path}@{application-font-name}.svg#@{application-font-name}') format('svg');
}

// Catchall baseclass
.pi {
  position: relative;
  top: 1px;
  display: inline-block;
  font: normal normal normal 16px/1 '@{application-font-name}';
  font-size: inherit;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.pi-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.pi-2x {
  font-size: 2em;
}
.pi-3x {
  font-size: 3em;
}
.pi-4x {
  font-size: 4em;
}
.pi-5x {
  font-size: 5em;
}
.pi-8x {
  font-size: 8em;
}


.pi-email-confirmation:before {
  content: "\e800";
}
.pi-download:before {
  content: "\e801";
}
.pi-create-account:before {
  content: "\e805";
}
.pi-arrow-left:before {
  content: "\e812";
}
.pi-arrow-right:before {
  content: "\e813";
}
.pi-arrow-circle-left:before {
  content: "\e815";
}
.pi-arrow-circle-right:before {
  content: "\e816";
}
.pi-arrow-left-step:before {
  content: "\e817";
}
.pi-arrow-right-step:before {
  content: "\e818";
}
.pi-helper:before {
  content: "\e819";
}
.pi-shopping-cart:before {
  content: "\e81a";
}
.pi-create-account2:before {
  content: "\e81b";
}
.pi-edit:before {
  content: "\e81c";
}
.pi-email:before {
  content: "\e81d";
}
.pi-heart-square:before {
  content: "\e81f";
}
.pi-facebook-square:before {
  content: "\e820";
}
.pi-facebook:before {
  content: "\e821";
}
.pi-heart:before {
  content: "\e822";
}
.pi-fax:before {
  content: "\e823";
}
.pi-gplus-square:before {
  content: "\e824";
}
.pi-gplus:before {
  content: "\e825";
}
.pi-linked-in:before {
  content: "\e826";
}
.pi-like:before {
  content: "\e827";
}
.pi-magnifier:before {
  content: "\e828";
}
.pi-more:before {
  content: "\e829";
}
.pi-phone:before {
  content: "\e82a";
}
.pi-pinterest:before {
  content: "\e82b";
}
.pi-check-circle:before {
  content: "\e82c";
}
.pi-star:before {
  content: "\e82d";
}
.pi-twitter:before {
  content: "\e82e";
}

.circle-icon {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}
