#contact-us-page {
  margin-top: 15px;

  .title {
    font-family: @font-family-light-condensed;
    font-size: 23px;
    text-align: center;
    line-height: 0.1;
  }

  #contact-us-form {
    p {
      font-family: @font-family-bold-condensed;
      color: @grey-dark;
    }
    #file-upload {
      .fu1 {
        font-family: @font-family-bold-condensed;
        color: @grey-dark;
        margin-right: 5px;
        font-size: 14px;
      }
      .fu2 {
        font-family: @font-family-light-condensed;
        margin-right: 10px;
        font-size: 14px;
      }
      i {
        color: @gold;
      }
    }

    #send-message {
      button {
        font-family: @font-family-bold-condensed;
        font-size: 18px;
        border-radius: 0;
      }
    }
  }

  #brand-info {
    p {
      font-family: @font-family-light-condensed;
      color: @grey-text;
      &.name {
        font-family: @font-family-bold;
        color: @grey-dark;
      }
    }
    i {
      color: @grey-dark;
    }
  }
}

@media (min-width: @screen-ss-min) {
  #contact-us-page {
    .title {
      border-bottom: 1px solid @gold;
      span {
        background-color: @white;
        padding: 0 20px;
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  #contact-us-page {
    .title {
      span {
        padding: 0 40px;
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  #contact-us-page {
    .title {
      span {
        padding: 0 80px;
      }
    }
  }
}

@media (min-width: @screen-lg-min) {
  #contact-us-page {
    .title {
      span {
        padding: 0 100px;
      }
    }
  }
}
