.btn, a.btn {
  padding: 5px 20px 2px;
  .fa {
    color: #fff;
    margin: 0 5px;
  }
  &.btn-transparent {
    .fa {
      color: @grey-text;
    }
  }
  &.btn-black {
    .fa {
      color: @btn-black-color;
    }
  }
}

.btn {
  border-radius: @border-radius-base;
  &.btn-sq {
    .button-size(2 + @padding-base-vertical; 2 * @padding-large-horizontal; @font-size-base; @line-height-base; @border-radius-base);
  }
  &.btn-file {
    position: relative;
    overflow: hidden;
    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }
  }
}

.form-control[readonly] {
  &.btn-file-readonly {
    background: transparent;
  }
}

.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}

.btn-red {
  .button-variant(@btn-red-color; @btn-red-bg; @btn-red-border);
}

.btn-light-blue {
  .button-variant(@btn-light-blue-color; @btn-light-blue-bg; @btn-light-blue-border);
}

.btn-blue {
  .button-variant(@btn-blue-color; @btn-blue-bg; @btn-blue-border);
}

.btn-dark-blue {
  .button-variant(@btn-dark-blue-color; @btn-dark-blue-bg; @btn-dark-blue-border);
}

.btn-white-text-blue {
  .button-variant(@btn-white-text-blue-color; @btn-white-text-blue-bg; @btn-white-text-blue-border);
}

.btn-black {
  .button-variant(@btn-black-color; @btn-black-bg; @btn-black-border);
}

.btn-transparent {
  .button-variant(@grey-text; transparent; @grey-text);
}

.btn-transparent-white {
  .button-variant(@white; transparent; @white);
}

.btn-gold {
  .button-variant(@gold; transparent; @gold);
}
.btn-gold-fill {
  .button-variant(@white; @gold; darken(@gold, 20%));
}

.btn-grey {
  .button-variant(@grey-text; @btn-grey-bg; @btn-grey-border);
}

//Special case because is transparent
.btn-transparent {
  color: @grey-text;
  background-color: transparent;
  border: @grey-text 2px solid;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @grey-text;
    border-color: darken(@grey-text, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: @grey-text;
    }
  }
}

//Special case because is transparent
.btn-transparent-white {
  color: @white;
  background-color: transparent;
  border: @white 2px solid;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: darken(@white, 12%);
    border-color: darken(@white, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: darken(@white, 12%);
    }
  }
}
