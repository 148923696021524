/* ======= Header ======= */
.header {
  background: #fff;
  z-index: 888;
  box-shadow: 0 0 15px darken(@gold, 10%);;

  header-borders {
    padding: 0;
  }

  .container {
    width: auto;
    @media (min-width: @screen-lg-min) {
      width: @container-lg;
    }
  }

  .logo {
    img {
      max-width: 104px;
      margin: auto;
    }
  }

  @media (min-width: 1600px) {
    .logo {
      img {
        display: block;
      }
    }
  }

  .top-header {
    display: block;
    text-align: left;

    .cart {
      text-align: right;
      a:hover {
        color: #595959;
        text-decoration: none;
        border: 1px solid @gold;
        padding: 11px 20px;
      }
      .fa {
        padding: 10px 0;
        margin: 0 20px;
      }
      .total-price {
        font-size: 12px;
        @media (min-width: @screen-lg-min) {
          font-size: @font-size-base;
        }
      }
      .total-items {
        position: absolute;
        top: 20px;
      }
    }

    .cart,
    .locale-switch,
    .social-share-icons {
      display: inline-block;

      .jssocials-shares {
        display: inline-block;
      }
    }

    .locale-switch {
      span {
        &.last {
          margin-right: 0;
          padding-right: 0;
        }
      }

      a {
        text-decoration: none;
        color: @blue;
      }

      .active {
        a {
          text-decoration: none;
          color: @gold;
        }
      }
    }
  }


  .main-nav {
    margin: 0;

    #navbar-collapse {
      @media (min-width: @screen-md) {
        padding: 0;
      }
    }

    .nav {
      .navbar-nav {
        .dropdown {
          margin-bottom: 0;
        }
      }

      .nav-item {
        color: @grey-text;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 14px;
        text-align: left;
        padding: 1px 15px;
        border-bottom: 1px solid @grey-light;
        @media (min-width: @screen-md) {
          border-bottom: none;
          border-right: 1px solid @grey-light;
          padding: 0;
          text-align: center;
        }

        &.dropdown-item {
          border-right: none;
          border-bottom: 1px solid @grey-light;
          text-align: left;

          &.last {
            border-bottom: none;
          }
        }

        &.home {
          border: none;

          a {
            padding: 5px;
          }
        }

        &.recent-posts {
          @media (min-width: @screen-md-min) {
            border-left: 1px solid @grey-light;
          }
        }

        .dropdown-menu {
          .active > a,
          .active > a:hover,
          .active > a:focus {
            background-color: transparent;
            color: @gold;
          }
        }

        a {
          @media (min-width: @screen-sm-min) {
            border: none;
            padding: 10px 3px;
          }
          @media (min-width: @screen-sm-max) {
            padding: 10px 15px;
          }
          color: @grey-text;
          font-weight: bold;
          .transition (none);

          &:hover {
            .transition (all 0.4s ease-in-out);
            background: none;

            .icon-bar {
              background: @gold;
            }
          }

          &:focus {
            outline: none;
            background: none;
          }

          &:active {
            outline: none;
            background: none;
          }
        }

        &.active {
          color: @gold;
        }

        &.last {
          margin-right: 0;
          border-right: 0;

          a {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }

      .open {
        & > a {
          background: none;
        }
      }
    }
  }

  .navbar-toggle {
    color: @gold;
    margin-right: 0;
    .border-radius(4px);

    &:focus {
      outline: none;
    }

    .icon-bar {
      background-color: #fff;
      color: @gold;
    }

    .icon-bar {
      width: 22px;
      transition: all 0.2s;
      background: @gold;
    }

    .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }

    &.collapsed {
      border: none;
      background: darken(@gold, 10%);

      &:hover {
        transition: all 0.2s;
        background: @gold;
      }

      .icon-bar {
        background: @white;
      }

      .top-bar {
        transform: rotate(0);
      }

      .middle-bar {
        opacity: 1;
      }

      .bottom-bar {
        transform: rotate(0);
      }
    }
  }
}

#global-search {
  &::-moz-focus-inner,
  &:focus-within {
    border-radius: 10px;
    padding: 0 5px;
    .box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }

  input,
  .form-control {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0 15px;
    background: transparent;
  }

  .input-group-addon {
    .fa {
      color: #bcb5b8;
    }
    border: none;
    background: none;

    &:focus {
      box-shadow: none;
    }

    .btn {
      font-size: 12px;
      color: #968056;
      background-color: transparent;
      border-bottom: 1px dotted #968056;
      border-radius: 0;
    }
  }
}

@media (min-width: @screen-sm) {
  .header-borders {
    border-top: 1px solid @grey-light;
    border-bottom: 1px solid @grey-light;
    padding: 12px 0;
  }

  .navbar-nav {
    float: none;
  }
}
