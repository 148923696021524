/* ======= FAQ Section ======= */
#faqs {
  color: #7F7F7F;
  .title {
    margin: 40px;
    margin-left: auto;
    color: @grey-dark;
    font-size: 18px;
  }

  .faqs {
    width: 100%;
    float: left;
    border-bottom: solid 1px @grey-light;

    .box {
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      i {
        color: @grey;
      }
      .question {
        color: @grey-dark;
        font-size: 18px;
      }
    }

    .faq {
      padding: 10px 0;
    }
    &:last-child {
      margin-bottom: 50px;
      border: none;
    }
  }
}

#faqs-title {
  border-bottom: solid 1px @grey-light;
  padding-bottom: 20px;
  .title {
    color: @gold;
    font-size: 30px;
  }
}