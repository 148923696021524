body {
  font-family: @font-name-open-sans-regular, @font-name-open-sans-light, @font-name-open-sans-cond-light;
  line-height: 1.8;
  color: @text-color;
  font-size: @font-size-base-mobile;
  @media (min-width: @screen-ss-min) {
    font-size: @font-size-base-mobile;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .container {
    @media (max-width: @screen-md-min) {
      width: 100%;
    }
  }

}

html, body {
  height: 100%;
}

h1, .h1 {
  font-size: @font-size-huge;
  color: @gold;
  letter-spacing: 4px;
}

//  34px
h2, .h2 {
  font-size: @font-size-huge;
  color: @gold;
}

//  34px
h3, .h3 {
  font-size: @font-size-large;
  color: @gold;
}

// 24px
h4, .h4 {
  font-size: @font-size-medium;
  color: @gold;
}

//20px
h5, .h5 {
  font-size: ceil(@font-size-base * 1.25);
  color: @grey-dark;
  font-family: @font-family-bold-condensed;
}

// 20px
h6, .h6 {
  font-size: @font-size-base;
  font-family: @font-family-bold-condensed;
  color: @gold;
}

// 16px

p {
  color: @text-color;
  font-size: @font-size-base;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: @grey-text;
  font-family: @font-family-light-condensed;
  font-size: 15px;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: @grey-text;
  font-family: @font-family-light-condensed;
  font-size: 15px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: @grey-text;
  font-family: @font-family-light-condensed;
  font-size: 15px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: @grey-text;
  font-family: @font-family-light-condensed;
  font-size: 15px;
}

.wrapper {
  align-items: stretch;
  min-height: 70%;
}

/* Align vertically a column */
.display-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  .display-cell {
    display: table-cell;
    vertical-align: middle;
    float: none;
    &.display-cell-bottom {
      vertical-align: bottom;
    }
  }
}

.panel {
  border: 2px solid;
  &.panel-primary {
    border-color: @primary-color;
  }
}

hr {
  margin-top: 12px;
  margin-bottom: 12px;
}

.popover {
  min-width: 250px;
}

.ignite-collapse {
  padding: 10px 0px 10px;
  margin: 0px 20px;
  a {
    display: block;
    &:after {
      font-family: 'FontAwesome';
      content: "\f068";
      float: right;
      overflow: no-display;
    }
    &.collapsed {
      &:after {
        content: "\f067";
      }
    }
  }
}

ol {
  counter-reset: li;
}

.cms-page {
  margin-top: 30px;
  margin-bottom: 50px;
  .title {
    color: @blue;
    margin-bottom: 0;
  }
  .sub-title {
    color: @blue-light;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.no-padding {
  padding: 0;
}

.hide-picture {
  overflow: hidden;
}

.jssocials-share {
  display: inline-block;
  .jssocials-share-link {
    color: @grey-text-light;
    margin: 0 15px !important;
  }
}

.form-control {
  border: 1px solid @grey-light;
}

.menu-profile {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar {
  background-color: #FFFFFF;
  padding: 30px;
  &.grey {
    background-color: @grey-dark;
  }
}