.jssocials-share-twitter .jssocials-share-link {
  color: #00aced; }
  .jssocials-share-twitter .jssocials-share-link .jssocials-share-label {
    color: #00aced; }
  .jssocials-share-twitter .jssocials-share-link:hover, .jssocials-share-twitter .jssocials-share-link:focus, .jssocials-share-twitter .jssocials-share-link:active {
    color: #0087ba; }
    .jssocials-share-twitter .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-twitter .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-twitter .jssocials-share-link:active .jssocials-share-label {
      color: #0087ba; }

.jssocials-share-facebook .jssocials-share-link {
  color: #3b5998; }
  .jssocials-share-facebook .jssocials-share-link .jssocials-share-label {
    color: #3b5998; }
  .jssocials-share-facebook .jssocials-share-link:hover, .jssocials-share-facebook .jssocials-share-link:focus, .jssocials-share-facebook .jssocials-share-link:active {
    color: #2d4373; }
    .jssocials-share-facebook .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-facebook .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-facebook .jssocials-share-link:active .jssocials-share-label {
      color: #2d4373; }

.jssocials-share-googleplus .jssocials-share-link {
  color: #dd4b39; }
  .jssocials-share-googleplus .jssocials-share-link .jssocials-share-label {
    color: #dd4b39; }
  .jssocials-share-googleplus .jssocials-share-link:hover, .jssocials-share-googleplus .jssocials-share-link:focus, .jssocials-share-googleplus .jssocials-share-link:active {
    color: #c23321; }
    .jssocials-share-googleplus .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-googleplus .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-googleplus .jssocials-share-link:active .jssocials-share-label {
      color: #c23321; }

.jssocials-share-linkedin .jssocials-share-link {
  color: #007bb6; }
  .jssocials-share-linkedin .jssocials-share-link .jssocials-share-label {
    color: #007bb6; }
  .jssocials-share-linkedin .jssocials-share-link:hover, .jssocials-share-linkedin .jssocials-share-link:focus, .jssocials-share-linkedin .jssocials-share-link:active {
    color: #005983; }
    .jssocials-share-linkedin .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-linkedin .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-linkedin .jssocials-share-link:active .jssocials-share-label {
      color: #005983; }

.jssocials-share-pinterest .jssocials-share-link {
  color: #cb2027; }
  .jssocials-share-pinterest .jssocials-share-link .jssocials-share-label {
    color: #cb2027; }
  .jssocials-share-pinterest .jssocials-share-link:hover, .jssocials-share-pinterest .jssocials-share-link:focus, .jssocials-share-pinterest .jssocials-share-link:active {
    color: #9f191f; }
    .jssocials-share-pinterest .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-pinterest .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-pinterest .jssocials-share-link:active .jssocials-share-label {
      color: #9f191f; }

.jssocials-share-email .jssocials-share-link {
  color: #3490F3; }
  .jssocials-share-email .jssocials-share-link .jssocials-share-label {
    color: #3490F3; }
  .jssocials-share-email .jssocials-share-link:hover, .jssocials-share-email .jssocials-share-link:focus, .jssocials-share-email .jssocials-share-link:active {
    color: #0e76e6; }
    .jssocials-share-email .jssocials-share-link:hover .jssocials-share-label, .jssocials-share-email .jssocials-share-link:focus .jssocials-share-label, .jssocials-share-email .jssocials-share-link:active .jssocials-share-label {
      color: #0e76e6; }

.jssocials-share-link {
  -webkit-transition: color 200ms ease-in-out;
          transition: color 200ms ease-in-out; }
  .jssocials-share-link.jssocials-share-link-count .jssocials-share-count {
    font-size: .5em; }

.jssocials-share-label {
  font-size: 0.8em;
  color: #444;
  -webkit-transition: color 200ms ease-in-out;
          transition: color 200ms ease-in-out; }

.jssocials-share-count-box {
  margin-left: 0.3em;
  border: 1px solid #efefef;
  border-radius: 0.3em; }
  .jssocials-share-count-box .jssocials-share-count {
    font-size: 0.8em;
    color: #444; }
