.datetimepicker {
  background-color: @white;
  color: @grey;
}

.datetimepicker thead tr:first-child th:hover,
.datetimepicker tfoot tr:first-child th:hover,
.datetimepicker table tr td span:hover,
.datetimepicker table tr td.hour:hover,
.datetimepicker table tr td.day:hover,
.datetimepicker table tr td.minute:hover {
  background: @primary-color;
}

.datetimepicker table tr td span.disabled:hover,
.datetimepicker table tr td.hour.disabled:hover,
.datetimepicker table tr td.day.disabled:hover,
.datetimepicker table tr td.minute.disabled:hover  {
  background: inherit;
}

.datetimepicker table tr td span.year.active,.datetimepicker table tr td span.year.active:hover,.datetimepicker table tr td span.year.active:hover:hover,
.datetimepicker table tr td span.month.active,.datetimepicker table tr td span.month.active:hover,.datetimepicker table tr td span.month.active:hover:hover,
.datetimepicker table tr td.active:hover, .datetimepicker table tr td.active:hover:hover, .datetimepicker table tr td.active.disabled:hover, .datetimepicker table tr td.active.disabled:hover:hover, .datetimepicker table tr td.active:active, .datetimepicker table tr td.active:hover:active, .datetimepicker table tr td.active.disabled:active, .datetimepicker table tr td.active.disabled:hover:active, .datetimepicker table tr td.active.active, .datetimepicker table tr td.active:hover.active, .datetimepicker table tr td.active.disabled.active, .datetimepicker table tr td.active.disabled:hover.active, .datetimepicker table tr td.active.disabled, .datetimepicker table tr td.active:hover.disabled, .datetimepicker table tr td.active.disabled.disabled, .datetimepicker table tr td.active.disabled:hover.disabled, .datetimepicker table tr td.active[disabled], .datetimepicker table tr td.active:hover[disabled], .datetimepicker table tr td.active.disabled[disabled], .datetimepicker table tr td.active.disabled:hover[disabled] {
  background: @primary-color;
}

.date {
  cursor: pointer;
}

