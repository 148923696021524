#dynamic-page {
  .row {
    margin: inherit;
  }

  #dynamic-filters {
    h4 {
      color: @grey-dark;
      font-size: 17px;
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 10px;
        a {
          font-family: @font-family-regular;
          color: @grey-text-light;
          font-size: 14px;
        }
        &.active {
          a {
            color: @grey-dark;
          }
        }
      }
    }
    .advertising {
      img {
        width: 100%;
      }
    }
  }

  .input-group {
    font-family: @font-family-regular;
    input {
      font-size: 14px;
    }
    .input-group-addon {
      padding: 0;
      button {
        width: 40px;
        height: 34px;
        padding: 0;
        border-radius: 0;
        span {
          margin: 0;
        }
      }
    }
  }

  .dynamic-entry {
    border-bottom: 1px solid lightgray;
    padding-top: 20px;
    padding-bottom: 20px;
    .dynamic-entry-text {
      margin-top: 15px;
      .dynamic-entry-title {
        margin-top: 0;
        font-family: @font-family-bold;
        font-size: 14px;
        color: @gold;
      }
      .dynamic-summary {
        font-family: @font-family-regular;
        color: @grey;
        font-size: 14px;
      }
    }
  }

  #dynamic-details {
    margin-top: 35px;
    .dynamic-header-div {
      margin-bottom: 15px;
      .dynamic-header {
        font-size: 30px;
        margin: 0;
        color: @grey-text-light;
      }
      .second-bar {
        padding-right: 50px;
      }
    }

    .title {
      font-family: @font-family-bold;
      font-size: 17px;
      color: @gold;
    }
    .sub-title {
      font-family: @font-family-light;
      font-size: 16px;
      color: @grey-dark;
    }
    .social {
      a {
        padding-left: 0;
      }
    }
    .content {
      font-family: @font-family-regular;
      font-size: 14px;
    }
    .attachments {
      .attachment-download {
        i {
          font-size: 40px;
        }
      }
    }
  }
}

@media (min-width: @screen-ss-min) {
  #dynamic-page {
    #dynamic-filters {
      h4 {
        padding-left: 15px;
      }
    }
    .dynamic-header {
      font-size: 34px;
    }
  }
}

@media (min-width: @screen-sm-min) {
  #dynamic-page {
    #dynamic-filters {
      h4 {
        padding-left: 15px;
      }
    }
    #dynamic-details {
      margin-top: 0;
      .dynamic-header-div {
        margin-bottom: 20px;
      }
    }
  }
}
