/* ======= FAQ Section ======= */
#faqs {
  h3 {
    margin: 50px;
    color: @black;
  }
  .search {
    padding: 50px;
    input {
      width: 50%;
      padding: 0 5px;
      height: 35px;
      font-size: 17px;
    }
    button {
      width: 20%;
      height: 35px;
    }
  }
}

#faqs-title {
  .faqs-title {
    padding-bottom: 30px;
    padding-left: 60px;
    padding-right: 60px;
    .title {
      color: @black;
    }
    .sub-title {
      font-weight: 400;
    }
  }
}

#faqs-photo {
  img {
    max-height: 600px;
  }
}

.faq {
  padding: 10px 40px;
  &.has-bg-color {
    background: @white-smoky;
  }
  button {
    text-align: left;
    line-height: 1.5;
    font-size: 15px;
    color: @grey-dark;
    white-space: normal;
  }
  p {
    font-size: 16px;
    padding: 15px 0;
  }
}
