.select2-container--bootstrap {
  .select2-results__group {
    background: black;
    line-height: 0;
    font-size: initial;
  }
  .select2-selection--single,
  input,
  textarea {
    border-color: @grey-light;
  }
  .select2-results__option--highlighted,
  .select2-results__option--highlighted[aria-selected] {
    background-color: @grey;
  }
}