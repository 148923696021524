#ModalController-popup-newsletter.modal-content {
  font-family: @font-family-regular;
  background-image: url("//images.k-lighting.com/assets/bower/images/default/popup-back.png");
  min-height: 450px;

  h1 {
    font-family: @font-family-regular;
    color: white;
    text-align: center;
    font-size: 22px;
    padding-top: 25px;

    @media (min-width: @screen-sm-min) {
      padding-top: 70px;
    }
    padding-bottom: 15px;
    line-height: 1.5em;
  }

  button {
    background-color: @black;
    color: @white;
    font-family: @font-family-regular;
    font-size: 20px;
    padding: 8px 50px 8px 50px;
  }

  p {
    color: @white;
    font-family: @font-family-light-condensed;
    text-align: center;
  }

  p.has-error {
    font-family: @font-name-open-sans-semi-bold;
  }
  .close-button {
    float: right;
    margin: 10px;
    width: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 1.50rem;
    background-color: white;
    font-family: @font-family-light-condensed;
    font-weight: 600;
  }
}
