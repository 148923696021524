#product {
  .btn {
    border-radius: 0;
  }

  input {
    line-height: 1.5;
  }

  .product-header-div {
    background-color: @grey-dark;

    .cms-block {
      background-color: @grey-dark;

      .navbar-default {
        background-color: @grey-dark;

        .breadcrumb {
          background-color: @grey-dark;
        }
      }
    }

    .route-back {
      font-family: @font-family-light-condensed;
      color: @white;
      padding: 0 30px;
      font-size: @font-size-base-mobile;

      span {
        margin-right: 10px;
      }
    }

    .product-header {
      margin: 0;
      padding: 5px 15px;
    }
  }

  #product-search-form {
    .form-group {
      margin: 0 0 15px 0;
    }

    .btn {
      width: 100%;
      padding: 6px;
      border-radius: 0;
    }
  }

  #product-list {
    margin-bottom: 20px;

    .product {
      width: 96%;
      @media (min-width: @screen-sm) {
        width: 42%;
      }
      @media (min-width: @screen-md) {
        width: 30%;
      }
      box-shadow: 0 0 15px #f8f4f3;
      margin: 10px;
      padding: 10px;
      float: left;

      .gallery {
        height: 240px;
        @media (min-width: @screen-sm-min) {
          height: 350px;
        }

        img {
          margin: 0 auto;
          max-height: 200px;
          @media (min-width: @screen-sm-min) {
            max-height: 310px;
          }
        }
      }

      .title {
        .text-overflow();
      }

      .info {
        height: 140px;
      }

      p {
        margin: 0;
        font-family: @font-family-light;

        .text-gold {
          margin: 3px 0;
        }
      }

      hr {
        margin: 2px 0;
        border-color: @grey-light;
        border-radius: 10px;
      }

      .sku {
        font-size: 15px;
      }

      i {
        color: @grey-light;
        margin-bottom: 10px;
      }

      .lSAction {
        .lSPrev,
        .lSNext {
          i {
            color: @gold;
          }
        }
      }

      a {
        color: @gold;
      }
    }

    .product-entry {
      .search-empty {
        padding: 30px;
        font-weight: bold;
      }
    }
  }

  #product-details {
    .product-gallery {
      background-color: @grey-lighter;

      .item {
        cursor: pointer;
        margin: 0 auto;
        max-height: 500px;

        img {
          margin: 0 auto;
          max-width: 450px;
          max-height: 500px;
        }
      }
    }

    .product-info {
      padding: 10px 10px 15px;
      box-shadow: 0 0 15px #ebe7e6;
      margin: 10px;
      @media (min-width: @screen-lg-min) {
        min-height: 475px;
      }

      .product-sku {
        color: @gold;
        font-family: @font-name-open-sans-semi-bold;
        font-size: 16px;
      }

      .product-description,
      .product-description > p {
        color: @black;
        font-size: 14px;
      }

      .price {
        font-size: 18px;
        font-family: @font-name-open-sans-semi-bold;
        color: @grey-dark;
        text-align: right;

        span {
          font-size: 14px;
          color: @grey-text;
        }
      }
    }

    .btn {
      border: none;
      font-family: @font-name-open-sans-semi-bold;

      padding: 14px;

      span {
        margin-left: 30px;
      }
    }
  }
}