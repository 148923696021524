.navbar-nav {
  .dropdown{
    cursor: pointer;
  }

  .sidebar-context-menu {
    background-color: @grey-dark;
    padding: 0;

    .sidebar-context-menu-header {
      font-family: @font-family-light-condensed;
      letter-spacing: 5px;
      margin: 0;
      padding: 15px;
    }

    .dropdown-menu,
    .menu {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        text-transform: uppercase;
        border-top: 1px solid @grey-text-light;

        &.active {
          a {
            color: @white;
            font-weight: bold;
          }
        }

        a {
          font-family: @font-family-light;
          color: @grey-text-light;
          font-size: 18px;
          text-transform: uppercase;
        }
      }
    }
  }
}