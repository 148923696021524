/* ======= Registration Section ======= */
#profile-page {
  #dropdown_menu_profile {
    width: 100%;
  }
}
#profile-page {
  background: url("//images.k-lighting.com/assets/bower/images/default/login.png");
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  padding: 25px;
  min-height: 550px;

  /* Full height */
  height: 100%;

  .navbar-nav {
    margin: 0;
    padding: 0;
    @media (min-width: @screen-md) {
      float: left;
    }

    .sidebar-context-menu .dropdown-menu,
    .sidebar-context-menu .menu {
      .caret {
        position: absolute;
        right: 20px;
        top: 25px;
      }
      @media (min-width: @screen-md) {
        display: block;
        z-index: 1;
      }
    }
  }


  .btn {
    border-radius: 0;
  }

  .panel {
    margin: 10px 0;

    &.panel-primary {
      border-color: @gold;

      fieldset {
        border: 2px solid @gold;
        padding: 10px;

        legend {
          width: auto;
          text-align: center;
          color: @gold;
          border-bottom: 0;
          margin: 40px 0;
        }

        .form-group {
          margin: 0 0 40px 0;

          .form-control {
            margin: 20px 0;
          }

          .controls {
            padding: 10px;
          }
        }
      }

      .new-password {
        fieldset {
          .form-group {
            .form-control {
              margin: 0;
            }
          }
        }
      }
    }

    .panel-body {
      padding: 30px 20px;
    }
  }

  #registration {
    .panel {
      margin: 20px 0;

      fieldset {
        legend {
          margin: 0;
        }

        .form-group {
          margin: 0;

          .form-control {
            margin: 0;
          }
        }
      }
    }
  }
}

.menu-profile {
  list-style-type: none;

  li {
    height: 60px;
    padding: 0;

    a {
      display: block;
      line-height: 2.5;
      text-transform: uppercase;
      font-family: @font-family-light-condensed;
      font-size: 20px;
      margin-bottom: 2px;
      color: @white;
    }

    &.active {
      background-color: @grey-lighter;
      color: @grey-dark;
    }
  }
}
