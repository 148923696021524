#breadcrumbs {
  background-color: @grey-dark;
  margin-bottom: 0;
  .navbar-default {
    background-color: @grey-dark;
    .breadcrumb {
      background-color: @grey-dark;
      .text-overflow();
    }
  }
  .route-back {
    font-family: @font-family-light-condensed;
    color: @white;
    padding: 0 30px;
    font-size: @font-size-base-mobile;
    span {
      margin-right: 10px;
    }
  }
  .header {
    margin: 0;
    padding: 10px 15px;
    color: @white;
    background-color: @grey-dark;
  }
}

.cms-block {
  .navbar-default {
    background-color: @white;
    border: none;
    .breadcrumb {
      background-color: @white;
      li + li:before {
        content: "\00a0>\00a0";
      }
      a, span {
        text-transform: uppercase;
        font-family: @font-family-light-condensed;
        font-size: 14px;
        color: @grey-text-light;
      }
    }
  }
}
