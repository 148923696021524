/* ======= Footer ======= */
.footer {
  background-color: #383a3d;
  color: #e5e5e5;
  padding: 0 0;

  .footer-content {
    background-color: @grey-dark;
    a {
      font-family: @font-family-bold-condensed;
      color: @gold;
      &:hover {
        color: @grey-text-light;
      }
      i {
        margin-left: 5px;
        font-weight: bold;
      }
    }
  }

  p {
    color: @white;
  }

  h3,
  h4 {
    color: #b7b7b7;
  }

  .bottom-bar {
    background-color: #1e2023;
    font-size: 10px;
    padding: 10px 0;
    .contact-us {
      font-size: 16px;
    }
    .copyright {
      display: inline-block;
      vertical-align: bottom;
      margin-top: 13px;
    }
    .livro-reclamacao {
      color: #fff;
      font-size: 14px;
    }
    a {
      color: #fff;
    }
    h3 {
      color: @grey-light;
      font-size: 18px;
      font-family: @font-name-open-sans-light;
    }
    .social-share,
    .social-share-icons {
      .item {
        margin: 0 15px 10px 15px;
        display: inline-block;
      }
      a {
        color: @white;
        font-size: 20px;
      }
    }
  }

  .footer-newsletter {
    ::-webkit-input-placeholder {
      color: @white;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: @white;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: @white;
    }
    :-ms-input-placeholder {
      color: @white;
    }
    color: @white;
    min-height: 100px;
    padding: 30px 0;
    text-align: center;

    input {
      color: @white;
      background-color: transparent;
    }
    button {
      border-radius: 0;
      padding: 5px 20px;
      i {
        color: @white;
        font-size: 20px;
      }
    }
  }
}
