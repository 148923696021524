.box-fill {
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 20px;
  &.disabled {
    opacity: 1;
  }
  &.box-lg {
    line-height: 40px;
  }
  &.box-grey {
    background-color: @grey;
    border-color: @grey;
    color: @white;
  }
  &.box-dark-grey {
    background-color: @grey-dark;
    border-color: @grey-dark;
    color: @white;
  }
  &.box-black {
    background-color: @black;
    border-color: @black;
    color: @white;
  }
  &.box-green {
    background-color: @green;
    border-color: @green;
    color: @white;
  }
  .box-edit {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: @font-size-small;
  }
}

.box-border {
  margin-bottom: 5px;
  border:solid 2px black;
  &.box-lg {
    line-height: 40px;
  }
  a {
    color: inherit;
  }
  &.green{
    border-color: @green;
  }
  .ignite-collapse {
    margin: 0;
    padding: 10px;
  }
}