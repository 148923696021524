/* ======= Registration Section ======= */
#register {
  background: url("/bower/images/default/login.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .btn {
    border-radius: 0;
  }

  .panel {
    margin: 20px 0;

    &.panel-primary {
      border-color: @gold;
      fieldset {
        border: 2px solid @gold;
        padding: 10px;
        legend {
          width: auto;
          text-align: center;
          color: @gold;
          border-bottom: 0;
          margin: 40px 0;
        }
        .form-group {
          margin: 0 0 40px 0;
          .form-control {
            margin: 20px 0;
          }
          .controls {
            padding: 10px;
          }
        }
      }

      .new-password {
        fieldset {
          .form-group {
            .form-control {
              margin: 0;
            }
          }
        }
      }
    }
    .panel-body {
      padding: 30px 20px;
    }
  }
  #registration {
    .panel {
      margin: 20px 0;
      fieldset {
        legend {
          margin: 0;
        }
        .form-group {
          margin: 0;
          .form-control {
            margin: 0;
          }
        }
      }
    }
  }
}
