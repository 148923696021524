.fa-spin {
  animation: fa-spin 1.4s infinite linear;
}
#curtain {
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
#loading {
  font-size: 150px;
  position: absolute;
  top: 20%;
  left: 35%;
  @media (min-width: @screen-sm-min) {
    left: 45%;
  }
}

#checkout {
  fieldset {
    border: solid 1px @grey-lighter;
    margin-bottom: 20px;
    padding: 0;
    &.order-summary {
      border-left: none;
      border-right: none;
      right: 0;
      p {
        font-size: 14px;
        margin: 0;
        .total {
          font-family: @font-family-bold;
        }
      }
      .items-summary {
        border-bottom: solid 1px @grey-lighter;
      }
    }

    .item {
      border-bottom: solid 1px @grey-lighter;
      margin: 2px 0;
      img.img-responsive{
        height: 200px;
        margin: 0 auto;
      }
      .product {
        padding: 2px;
      }
    }
  }
}