/* ======= FAQ Section ======= */
.steps {
  margin-top: 20px;
  color: @gold;

  .display-cell {
    p {
      color: @gold;
      min-height: 100px;
    }
  }
}
