#cms-page {
  h1, h2, h3, h4, h5, h6 {
    background-color: transparent !important;
  }
  h1 {
    color: @grey;
    font-size: 43px;
    font-family: @font-family-light-condensed;
  }
  h2, h3 {
    font-family: @font-family-bold-condensed;
    color: @gold;
    margin-bottom: 20px;
    font-size: 25px;
  }
  h4 {
    color: @grey-text;
  }
  h5, h6 {
    color: @grey-text;
  }
  img {
    width: 100%;
    height: auto;
  }
  p {
    background-color: transparent !important;
    text-align: justify;
    font-family: @font-family-light;
    margin-bottom: 50px;
    font-size: 18px;
  }
  .col-xs-24 {
    margin-top: 20px;
  }
  .attach-description {
    text-align: center;
  }
}

.tittle {
  color: @grey-dark;
  font-family: @font-family-bold-condensed;
  font-size: 24px;
  @media (min-width: @screen-md-min) {
    font-size: 44px;
  }
}

.sub-title {
  color: @grey;
  font-family: @font-family-light-condensed;
  font-size: 20px;
  @media (min-width: @screen-md-min) {
    font-size: 44px;
  }
}
