#about-us-page {
  #container-photo {
    padding: 0;
    .main-photo {
      padding: 0;
    }
    h1 {
      position: absolute;
      top: 35%;
      color: @grey;
      font-size: 43px;
      font-family: @font-family-light-condensed;
      span {
        color: @grey-dark;
        font-size: 44px;
        font-family: @font-family-bold-condensed;
      }
    }
  }

  #about-us-body {
    h2 {
      font-family: @font-family-bold-condensed;
      color: @gold;
      margin-bottom: 20px;
      font-size: 25px;
    }
    p {
      font-family: @font-family-light;
      margin-bottom: 50px;
      font-size: 20px;
    }
  }
}